import React, { createContext, useReducer } from 'react';

import * as devicesApi from 'services/devices';
import { serializeApiErrors } from 'utils/errors';
import {
  loadAllPages,
  loadByIdsInBatches,
  makeReducer,
  normalizePagingResponse,
  sortByCreatedAtDesc,
} from 'utils/functions';
import { Device, RawDevice } from 'utils/types';

import { formatDevice } from './functions';
import {
  AssignDevicesCallback,
  DevicesContext as DevicesContextType,
  DevicesState,
  GetDevicesCallback,
  GetDevicesOptions,
  LoadDevicesForPatientsFunction,
  LoadDevicesFunction,
  UpdateDeviceCallback,
  UpdateDeviceOptions,
} from './types';

const getInitialState = (): DevicesState => ({
  devices: [],
  device: null,
  loading: false,
  error: null,
});

const DevicesContext = createContext<DevicesContextType>({
  ...getInitialState(),
  loadDevices: async () => {},
  loadDevicesForPatients: async () => {},
  getDevices: async () => {},
  updateDevice: async () => {},
  assignDevice: async () => {},
  appendDevices: () => {},
  resetDevices: () => {},
  pruneDevicesByOrderId: () => {},
});

const DevicesProvider = (props: any) => {
  const [state, setState] = useReducer(makeReducer<DevicesState>(), getInitialState());

  const loadDevices: LoadDevicesFunction = async (params, callback?) => {
    setState({ loading: true, error: null });

    try {
      const query: Record<string, string | boolean> = {};

      if (params.projectId) {
        query.projectIds = params.projectId;
      }

      if (typeof params.patientIdNull !== 'undefined') {
        query.patientIdNull = params.patientIdNull;
      }

      if (params.barcodeLike) {
        query.barcodeLike = params.barcodeLike;
      }

      if (Array.isArray(params.notStatuses)) {
        query.notStatuses = `${params.notStatuses.join(',')},cancelled`;
      } else {
        query.notStatuses = 'cancelled';
      }

      const sortBy = params.sortBy || 'createdAt';
      const isDescending = params.sortOrder !== 'asc';

      let data = await devicesApi.loadDevices(query, {
        page: params.page,
        pageLength: params.pageLength,
        sortBy,
        isDescending,
        includeTotalCount: params.includeTotalCount,
      });

      if (params.includeCompanionDevices && data.results.some((r: RawDevice) => r.shipmentCode)) {
        const companionQuery: Record<string, string | boolean> = {};
        companionQuery.orderIds = data.results.map((r: RawDevice) => r.orderId);
        companionQuery.shipmentCodes = data.results.map((r: RawDevice) => r.shipmentCode);

        data = await devicesApi.loadDevices(companionQuery, {
          page: params.page,
          pageLength: params.pageLength,
          sortBy,
          isDescending,
          includeTotalCount: params.includeTotalCount,
        });
      }

      const formattedDevices = data.results.map(formatDevice);

      setState({
        devices: formattedDevices,
      });

      const pagingInfo = normalizePagingResponse(data.paging);

      if (callback) {
        await callback(formattedDevices, pagingInfo);
      }
    } catch (e) {
      setState({ error: { timestamp: Date.now(), message: serializeApiErrors(e) } });

      if (callback) {
        await callback(null, null);
      }
    }

    setState({ loading: false });
  };

  /**
   * Loads all available devices that belong to the given list of patient ids.
   */
  const loadDevicesForPatients: LoadDevicesForPatientsFunction = async (params, callback?) => {
    setState({ loading: true, error: null });

    try {
      const { patientIds, projectId } = params;

      const query: Record<string, string | boolean | string[]> = { projectIds: projectId };

      if (typeof params.patientIdNull === 'boolean') {
        query.patientIdNull = params.patientIdNull;
      }

      if (Array.isArray(params.statuses)) {
        query.statuses = params.statuses.join(',');
      }

      if (Array.isArray(params.notStatuses)) {
        query.notStatuses = `${params.notStatuses.join(',')},cancelled`;
      } else {
        query.notStatuses = 'cancelled';
      }

      if (params.hydration) {
        query.hydration = params.hydration;
      }

      const results = await loadByIdsInBatches<Device>(devicesApi.getDevices, 'patientIds', patientIds, query);

      const formattedDevices = results.map(formatDevice).sort(sortByCreatedAtDesc);

      setState({
        devices: formattedDevices,
      });

      if (callback) {
        await callback(formattedDevices);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const getDevices = async (
    params: string | Record<string, any>,
    options: GetDevicesOptions = {},
    callback?: GetDevicesCallback
  ): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const payload = typeof params === 'string' ? { projectIds: params } : params;
      payload.notStatuses = 'cancelled';

      type PayloadType = Parameters<typeof devicesApi.getDevices>[0];
      const devices = await loadAllPages<RawDevice, PayloadType>(devicesApi.getDevices, payload, {
        pageLength: 100,
      });

      const formattedDevices = devices.map(formatDevice).sort(sortByCreatedAtDesc);

      if (!options.skipStateUpdate) {
        setState({
          devices: formattedDevices,
        });
      }

      if (callback) {
        await callback(formattedDevices);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    } finally {
      setState({ loading: false });
    }
  };

  const updateDevice = async (
    id: string,
    data: devicesApi.UpdateDevicePayload,
    options: UpdateDeviceOptions = {},
    callback?: UpdateDeviceCallback
  ): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const device = await devicesApi.updateDevice(id, data);

      const formattedDevice = formatDevice(device);

      if (options.removeAfterUpdate) {
        setState({
          devices: state.devices.filter((d) => d.id !== id),
          device: state.device && state.device.id === id ? null : state.device,
        });
      } else if (!options.skipStateUpdate) {
        setState({
          devices: state.devices.map((d) => (d.id === id ? formattedDevice : d)),
          device: state.device && state.device.id === id ? formattedDevice : state.device,
        });
      }

      if (callback) {
        await callback(formattedDevice);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const assignDevice = async (
    data: devicesApi.AssignDevicePayload,
    callback?: AssignDevicesCallback
  ): Promise<void> => {
    setState({ loading: true, error: null });

    try {
      const assignment = await devicesApi.assignDevices(data);
      if (callback) {
        await callback(assignment);
      }
    } catch (e) {
      setState({
        error: { timestamp: Date.now(), message: serializeApiErrors(e) },
      });

      if (callback) {
        await callback(null);
      }
    }

    setState({ loading: false });
  };

  const appendDevices = (deviceList: Device[]): void => {
    setState({ devices: [...state.devices, ...deviceList].sort(sortByCreatedAtDesc) });
  };

  const resetDevices = (): void => {
    setState({ ...getInitialState() });
  };

  const pruneDevicesByOrderId = (orderId: string): void => {
    setState({
      devices: state.devices.filter((dev) => dev.orderId !== orderId),
      device: state.device && state.device.orderId === orderId ? null : state.device,
    });
  };

  return (
    <DevicesContext.Provider
      value={{
        devices: state.devices,
        device: state.device,
        loading: state.loading,
        error: state.error,
        loadDevices,
        loadDevicesForPatients,
        getDevices,
        updateDevice,
        assignDevice,
        appendDevices,
        resetDevices,
        pruneDevicesByOrderId,
      }}
      {...props}
    />
  );
};

const useDevices = (): DevicesContextType => React.useContext(DevicesContext);

export { DevicesProvider, useDevices };
