import { httpClient } from 'services/index';
import { PagingRequest } from 'utils/types';

export const loadDevices = (params: Record<string, any>, paging: PagingRequest): Promise<any> =>
  httpClient.get('/devices', { params: { ...params, ...paging } }).then((_) => _.data);

export const getDevices = (params: Record<string, any>, paging: PagingRequest): Promise<any> => {
  const headers: Record<string, string> = {};

  if (params.hydration?.length > 0) {
    headers['tasso-hydration'] = params.hydration.join(',');
    delete params['hydration'];
  }

  return httpClient.get('/devices', { headers, params: { ...params, ...paging } }).then((_) => _.data);
};

export type AssignDevicePayload = {
  patientId: string;
  barcodes: string[];
};

export const assignDevices = (data: AssignDevicePayload): Promise<any> =>
  httpClient.post(`/assignments`, data).then((_) => _.data.results);

export type UpdateDevicePayload = {
  patientId?: string;
  replacementHarmCaused?: boolean;
  replacementReason?: string;
  replacementDescription?: string;
  barcode?: string;
};

export const updateDevice = (id: string, data: UpdateDevicePayload): Promise<any> =>
  httpClient.patch(`/devices/${id}`, data).then((_) => _.data.results);
